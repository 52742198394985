@font-face {
    font-style: normal;
    font-family: "Inter";
    font-display: swap;
    src:  url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
}
@font-face {
    font-style: normal;
    font-family: "Libre Baskerville";
    font-display: swap;
    src:  url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap");
}


